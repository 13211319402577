// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'name'
  }, {
    prop: 'rank'
  }, {
    prop: 'prizeable'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'count'
  }, {
    prop: 'probability'
  }, {
    prop: 'online',
    back: true
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
